<script setup lang="ts">
import type { BannerContentProps } from "~/components/types/props.types";

const scroll = (to: string) => {
  const scrollTo = document.getElementById(to);
  scrollTo?.scrollIntoView({
    behavior: "smooth",
  });
};

const props = withDefaults(defineProps<BannerContentProps>(), {
  formType: "online",
  bannerImage: () => ({
    alt: "",
    url: "/images/banner.png",
  }),
  bannerImageOne: () => ({
    alt: "",
    url: "/images/banner_list_1.png",
  }),
  bannerImageTwo: () => ({
    alt: "",
    url: "/images/banner_list_2.png",
  }),
  dictionary: () => ({
    textOnline: {
      title: "Получите заём <br/>в офисе <br/><b>без процентов*</b>",
      code: "text-online",
      type: "text",
      value: "до 180 дней<br/>на сумму до 100 000 ₽",
    },
    textOffline: {
      title: "Получите заём <br/>в офисе <br/><b>без процентов*</b>",
      code: "text-offline",
      type: "text",
      value: "до 180 дней<br/>на сумму до 100 000 ₽",
    },
    textOffline1: {
      title: "Текст оффлайн 1",
      code: "text-offline-1",
      type: "text",
      value: "Получите сумму больше<br/>в офисах Быстроденьги",
    },
    textOffline2: {
      title: "Текст оффлайн 2",
      code: "text-offline-2",
      type: "text",
      value: "Одобрение заявок<br/>в офисах Быстроденьги выше",
    },
  }),
});

const text = computed(() =>
  props.formType === "online"
    ? props.dictionary.textOnline
    : props.dictionary.textOffline,
);
</script>
<template>
  <div v-if="text?.value" class="container lg:-mb-96">
    <div class="main-banner">
      <div class="main-banner__wrapper">
        <div>
          <h3 class="main-banner__title" v-html="text.title"></h3>
          <div class="main-banner__text" v-html="text.value"></div>
        </div>
        <a
          href="#formBlock"
          class="main-banner__button"
          @click.prevent="scroll('formBlock')"
          >Получить бесплатно</a
        >
        <div v-if="formType === 'office'" class="main-banner__options">
          <div class="main-banner__options-item">
            <img
              class="shrink-0"
              :src="bannerImageOne.url"
              :alt="bannerImageOne.alt"
            />
            <div v-html="dictionary.textOffline1?.value" />
          </div>
          <div class="main-banner__options-item">
            <img
              class="shrink-0"
              :src="bannerImageTwo.url"
              :alt="bannerImageTwo.alt"
            />
            <div v-html="dictionary.textOffline2?.value" />
          </div>
        </div>
      </div>
      <div class="main-banner__image">
        <div
          class="main-banner__image-bg"
          :style="{
            backgroundImage: `url(${bannerImage.url})`,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "MainBanner";
</style>
